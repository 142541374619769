// Global component styles

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

a {
  &:focus {
    outline: none;
  }
}

.homeBlock i{
  font-size: 100px;
  color: #3F9464;
  opacity: 0.5;
}

.homeBlock i:hover{
  opacity: 1;
  font-size: 110px;
}

.homeBlock span{
  font-weight: bold;
}

.showWhenDone {
  display: none;
}

.row.ip.deleted {
  background-color: rgba(234, 14, 14, 0.35);
}

.row.ip.whitelisted {
  background-color: rgba(63, 191, 63, 0.35);
}

.queueRow.whitelisted {
  background-color: rgba(63, 191, 63, 0.35);
}

.whitelisted{
  background-color: rgba(63, 191, 63, 0.35);
}

/** FourbisButton  **/
.fourbisButton {
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: #4CB078;
  opacity: 0.5;
}

.fourbisButton:hover {
  opacity: 1;
}

.fourbisButton:disabled {
  background-image: linear-gradient(45deg, #4cb078 15%, #58d18e 15%, #58d18e 50%, #4cb078 50%, #4cb078 65%, #58d18e 65%, #58d18e 100%);
  background-size: 28.28px 28.28px;
}

.fourbisButton:disabled:hover {
  opacity: 0.5;
  background-image: linear-gradient(45deg, #4cb078 15%, #58d18e 15%, #58d18e 50%, #4cb078 50%, #4cb078 65%, #58d18e 65%, #58d18e 100%);
  background-size: 28.28px 28.28px;
}

/** FourbisButtonDanger  **/
.fourbisButtonDanger {
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: #ff0000;
  opacity: 0.5;
}

.fourbisButtonDanger:hover {
  opacity: 1;
}

.fourbisButtonDanger:disabled {
  background-image: linear-gradient(45deg, #ff0000 15%, #c20808 15%, #c20808 50%, #ff0000 50%, #ff0000 65%, #c20808 65%, #c20808 100%);
  background-size: 28.28px 28.28px;
}

.fourbisButtonDanger:disabled:hover {
  opacity: 0.5;
  background-image: linear-gradient(45deg, #ff0000 15%, #c20808 15%, #c20808 50%, #ff0000 50%, #ff0000 65%, #c20808 65%, #c20808 100%);
  background-size: 28.28px 28.28px;
}

/** FourbisButtonRefresh **/
.fourbisButtonRefresh {
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: #0094C7;
  opacity: 0.5;
}

.fourbisButtonRefresh:hover {
  opacity: 1;
}

.fourbisButtonRefresh:disabled {
  background-image: linear-gradient(45deg, #0094c7 25%, #007ba8 25%, #007ba8 50%, #0094c7 50%, #0094c7 75%, #007ba8 75%, #007ba8 100%);
  background-size: 28.28px 28.28px;
}

.fourbisButtonRefresh:disabled:hover {
  opacity: 0.5;
  background-image: linear-gradient(45deg, #0094c7 25%, #007ba8 25%, #007ba8 50%, #0094c7 50%, #0094c7 75%, #007ba8 75%, #007ba8 100%);
  background-size: 28.28px 28.28px;
}

.portalTitle {
  color: $primary;
  font-size: xx-large;
  font-weight: bold;
}

.loadingDiv {
  align-content: center;
  text-align: center;
  height: 70px;
  background-color: #8fd8ad;
  border-left: 2px solid #4CB07A;
  border-right: 2px solid #4CB078;
}

.fourbisContainer {
  border: 2px solid #4CB078;
  background-color: white;
  margin-top: 20px;
  padding: 10px;
}

.ContentBlock {
  display: inline-block;
  border: 1px solid black;
  text-align: center;
  background-color: #4CB078;
  height: 300px;
  transition: 0.3s;
}

.ContentBlock:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.ContentBlock h3 {
  font-weight: bold;
}

#fail2banDashboard {
  padding-left: 17px;
  list-style-type: decimal;
}

#loading {
  align-content: center;
  text-align: center;
}

/** LOADER **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #419766;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// Main page wrapper
#wrapper {
  display: flex;

  #content-wrapper {
    background-color: #E5E5E5;
    width: 100%;
    overflow-x: hidden;

    #content {
      flex: 1 0 auto;
    }
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, .5);
  line-height: 46px;

  &:focus,
  &:hover {
    color: white;
  }

  &:hover {
    background: $gray-800;
  }

  i {
    font-weight: 800;
  }
}
