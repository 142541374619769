// customize some Bootstrap variables
//$primary: #7c0094;
$secondary: #FFF;
$info: #17a2b8;

$dark: #191919;
$input-btn-padding-y: 0.175rem;
$input-btn-padding-x: 0.35rem;
$btn-font-size: .875em;
$btn-border-radius: 0;


// the ~ allows you to reference things in node_modules
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "../startbootstrap-sb-admin-2/scss/sb-admin-2";
@import '~chosen-js/chosen.min.css';
@import '~summernote/dist/summernote-bs4.css';
//@import '~codemirror/lib/codemirror.css';
@import '~daterangepicker/daterangepicker.css';
@import "~datatables/media/css/jquery.dataTables.css";

.btn-primary {
  @include button-variant($primary, $white);
  color: $white;
}

@import "fourbis";

body {
  overflow-x: hidden;
  background: #2a62bc;
}