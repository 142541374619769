.border-left-primary {
  border-left: .25rem solid $primary !important;
}

.border-left-success {
  border-left: .25rem solid $success !important;
}

.border-left-info {
  border-left: .25rem solid $info !important;
}

.border-left-warning {
  border-left: .25rem solid $warning !important;
}

.border-left-danger {
  border-left: .25rem solid $danger !important;
}

.border-bottom-primary {
  border-bottom: .25rem solid $primary !important;
}

.border-bottom-success {
  border-bottom: .25rem solid $success !important;
}

.border-bottom-info {
  border-bottom: .25rem solid $info !important;
}

.border-bottom-warning {
  border-bottom: .25rem solid $warning !important;
}

.border-bottom-danger {
  border-bottom: .25rem solid $danger !important;
}
