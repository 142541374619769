.slider {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.slide {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  /*background: #FFFFFF center center;*/
}

.slide.active {
  transform: translateX(-100%);
}

.slide .info {
  /*background-color: rgba(255, 255, 255, 0.7);*/
  color: #333;
  /*padding: 20px 15px;*/
  position: absolute;
  opacity: 0;
  top: 45px;
  /*left: 30px;*/
  /*text-align: center;*/
  width: 100%;
  max-width: 100%;
}

.slide.active .info {
  opacity: 1;
  transform: translateY(-40px);
  transition: all 0.2s ease-in-out 0.2s;
}

.slide .info h1 {
  margin: 10px 0;
}

.slide .info p {
  letter-spacing: 1px;
}

.eraser {
  background: #fff;
  position: absolute;
  transition: transform 0.2s ease-in-out;
  top: 69px;
  left: 100%;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.eraser.active {
  transform: translateX(-100%);
}


.overflow-card .overflow-content {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.overflow-card:hover .overflow-content {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.overflow-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color:  #fff #03689C;
}

.overflow-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color: #fff #03689C;
}

.cursor-pointer {
  cursor: pointer;
}

.explorer_selected {
  background: rgb(139, 205, 255);
  padding-left: 6px;
  padding-right: 6px;
}

.grid-row:hover {
  background: lightgrey;
}

.logo_img_select {
  width: 64px;
  cursor: pointer;
}

.logo_img_select:hover {
  width: 70px;
}

.bubble {
  position: relative;
  background: lightgrey;
  border-radius: 40px;
  padding: 3px 8px 7px;
  text-align: center;
  color: #000;
}

.bubble-bottom-left:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 13px solid lightgrey;
  border-right: 12px solid transparent;
  border-top: 12px solid lightgrey;
  border-bottom: 9px solid transparent;
  left: 16px;
  bottom: -10px;
}

.bubble-admin {
  position: relative;
  background: #0d6efd;
  border-radius: 40px;
  padding: 3px 8px 7px;
  text-align: center;
  color: #000;
}

.bubble-bottom-left-admin:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 13px solid #0d6efd;
  border-right: 12px solid transparent;
  border-top: 12px solid #0d6efd;
  border-bottom: 9px solid transparent;
  left: 16px;
  bottom: -10px;
}

.bubble-bottom-right-admin:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  transform: rotate(90deg);
  border-left: 13px solid #0d6efd;
  border-right: 12px solid transparent;
  border-top: 12px solid #0d6efd;
  border-bottom: 9px solid transparent;
  right: 16px;
  bottom: -10px;
}
.sk-rotating-plane {
  width: 24px;
  height: 24px;
  background: #FAC300;
  -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;

  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(242, 242, 242, 0.5);
}

.htmx-indicator {
  display: none;
  transition: opacity 200ms ease-in;
}

.htmx-request .htmx-indicator {
  display: block;
}

.htmx-request.htmx-indicator {
  display: block;
}

.CodeMirror {
  resize: vertical;
  overflow: auto !important;
}


.fa-spinner, .fa-circle-notch {
  animation: fa-spin 1s linear infinite;
}
.webcluster-favourite {
  font-size: 20px;

  &:hover{
    font-size: 22px !important;
  }
}

.a2lix_translationsFields {
  padding-top: 30px;
}

.webCluster-list {
  max-height: 70vh;
  overflow-y: scroll;
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}

.navbar-nav.toggled div.sidebar-brand-icon {
  display: block !important;
}

.client_dropdown {
  font-size: 35px;
  color: $primary;
  opacity: 0.5;
}

.client_dropdown:hover {
  opacity: 1;
}

.custom-select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

.sweet-alert .title {
  text-transform: none !important;
}

table.dataTable.fixedHeader-floating {
  z-index: 1;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  display: inline-flex;
  width: 33%;
  border-right: 1px solid #95c337;
  padding: 5px;
}

table.dataTable > tbody > tr.child span.dtr-title {
  padding-right: 5px;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  width: 100%;
}

table.dataTable > tbody > tr.child ul.dtr-details .dtr-data {
  word-break: break-word;
}

table tr td {
  word-break: break-all;
}

.sidebar-panel > nav .sub-menu li.active > a {
  background: #95c337;
  color: $dark;
}

.sidebar-panel > nav > ul > li {
  width: 100%;
}


/*** tags ***/
.tag {
  padding: 0.1rem 0.5rem;
  margin-right: 2px;
}

.tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

.tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}

.tag [data-role="move"] {
  margin-left: 8px;
  cursor: crosshair;
}

.tag [data-role="move"]:after {
  padding: 0px 2px;
}

/*** header ***/
@media (max-width: 48em) {
  .header .brand .brand-logo {
    width: 60px;
  }
}


.height-fit-content {
  height: fit-content;
}

.swal2-popup .swal2-content {
  z-index: 2 !important;
}

.card {
  .card-header {
    // Format Dropdowns in Card Headings
    .dropdown {
      line-height: 1;

      .dropdown-menu {
        line-height: 1.5;
      }
    }
  }

  // Collapsable Card Styling
  .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;

    &::after {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 51px;
      font-weight: 900;
      content: '\f107';
      font-family: 'Font Awesome 5 Free';
      color: $gray-400;
    }

    &.collapsed {
      border-radius: $card-border-radius;

      &::after {
        content: '\f105';
      }
    }
  }
}


.button-animation {
  position: relative;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: "";
    background: #00aa24;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 1.0s
  }

  &:active::after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }
}

.form-control.error {
  border: 1px solid $red;
  width: 100%;
  font-size: 1rem;
}

label.error {
  color: $red;
  font-size: 0.8rem;
  position: relative;
  line-height: 1;
  width: auto;
}


.fab::before {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';

  &.fa-sync {
    content: '\f021';
  }

  &.fa-print {
    content: '\f02f';
  }

  &.fa-trash {
    content: '\F1F8';
  }

  &.fa-copy {
    content: '\f0c5';
  }
}

//.chosen-container.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
//  background: none!important;
//}

.chosen-container .chosen-results {
  max-height: 170px;
}

.chosen-container .chosen-results li.disabled-result {
  color: transparent;
  font-size: 1px;
  line-height: 1px;
}

//.chosen-container.chosen-with-drop .chosen-drop {
//  position: relative;
//}

.swal2-content form.form-horizontal .form-group .chosen-container {
  overflow: hidden;
}

.swal2-content .chosen-container.chosen-with-drop .chosen-drop {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.56);
  /* position: absolute; */
}

.swal2-content .chosen-relative .chosen-container.chosen-with-drop .chosen-drop {
  position: relative;
}

.form-group.error .chosen-container .chosen-single, .form-group.error .chosen-container .chosen-choices {
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.emailBoxSubmit {
  background-color: #f0f0f0 !important;
  transition: 2s !important;
}

.emailBoxSubmit:hover {
  background-color: #4CB078 !important;
}

form ul.list.m2m {
  padding-left: 0;
}

form ul.list.m2m li {
  list-style: none;
  padding-bottom: 5px;
  background: white;
}

#domain_form {
  display: block;
}

#domain_form input, #domain_form select {
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  background: #f8fafe;
  border: 1px solid #bbb;
  height: 43px;
  width: 100%;
}

#domain_form .row {
  margin-bottom: 25px;
}

#whois {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  background: #f8fafe;
}

.drk_button {
  margin-top: .5rem !important;
  cursor: pointer;
  font-weight: 500;
  color: #ffffff !important;
  border-width: 0 !important;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: 'Rubik', Helvetica, Arial, Lucida, sans-serif !important;
  text-transform: uppercase !important;
  padding: 14px 28px;
  background-color: #263547;
  transition: 500ms;
}

.drk_button:hover {
  background-color: darkorange;
}

.drk_button:disabled {
  background-color: grey;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid darkseagreen;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.fit-image {
  width: 100%;
  object-fit: cover;
  height: 300px;
  background-color: #FFFFFF;
}

// Loading 4BIS logo
.sk-cube-grid {
  width: 100px;
  height: 100px;
  margin: 100px auto;
  display: none;
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -50px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  overflow: hidden;
}

.sk-cube img {
  position: absolute;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
}

.sk-cube-grid .sk-cube2 img {
  left: -100%;
}

.sk-cube-grid .sk-cube3 img {
  right: 0;
}

.sk-cube-grid .sk-cube4 img {
  bottom: -100%;
}

.sk-cube-grid .sk-cube5 img {
  bottom: -100%;
  left: -100%;
}

.sk-cube-grid .sk-cube6 img {
  right: 0;
  bottom: -100%;
}

.sk-cube-grid .sk-cube7 img {
  bottom: 0;
}

.sk-cube-grid .sk-cube8 img {
  bottom: 0;
  left: -100%;
}

.sk-cube-grid .sk-cube9 img {
  bottom: 0;
  right: 0;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #c3c3c3ba;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

/*Overview Pages landing page generator*/

.savedPages .row:nth-child(even), .publishedPages .row:nth-child(even) {
  background: #CCC;
}

.savedPages .row:nth-child(odd), .publishedPages .row:nth-child(odd) {
  background: #FFF;
}

.savedPages .row:first-child, .publishedPages .row:first-child {
  border-bottom: 2px solid black;
}


.navbar-dark .navbar-nav .nav-link{
  color: white;
}

//#wrapper{
// margin-top: 70px;
//}

#content-wrapper{
  //margin-left: 14rem;
  height: calc(100vh - 50px);
}


.slideout-disable-body {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #59595982;
  z-index: 20;
}

.slideout {
  position: absolute;
  width: 0;
  height: calc(100vh - 50px);
  background: white;
  z-index: 21;
  right: 0;
  overflow-x: hidden;
  white-space: nowrap;
  -webkit-box-shadow: -25px -1px 95px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -25px -1px 95px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -25px -1px 95px 0px rgba(0, 0, 0, 0.75);

  .card-footer{
    position: fixed;
    bottom: 0;
    z-index: 1030;
    background-color: #f7f7f7;
    width: 100%;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}


.navbar .nav-link{
  font-weight: 500;
}

.login-col{
  max-width: 450px;
}